<script lang="ts" setup>
const emit = defineEmits(['toast-closed', 'toast-opened']);

const props = defineProps<{
  type: string;
  title: string;
  content: string;
  duration?: number;
  position?: string;
  teleportTarget?: string;
  closeToast: () => void;
}>();

const isVisible = ref(false);

onMounted(() => {
  isVisible.value = true;
  emit('toast-opened');
  setTimeout(() => {
    emit('toast-closed');
    isVisible.value = false;
  }, props.duration);
});

const toastClasses = computed(() => {
  return ['tw-toast', `toast-position--${props.position}`, `toast-type--${props.type}`];
});

function close() {
  emit('toast-closed');
  props.closeToast();
}
</script>

<template>
  <Teleport :to="props.teleportTarget">
    <Transition name="fade">
      <div v-if="isVisible" :class="toastClasses">
        <button class="tw-toast-close-button" type="button" @click="close">
          <Icon name="bi:x" />
        </button>
        <header v-if="props.title" class="tw-toast-header">
          <strong>{{ props.title }}</strong>
        </header>
        <p v-if="props.content" class="tw-toast-body">{{ props.content }}</p>
        <slot />
      </div>
    </Transition>
  </Teleport>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/tw-variables.scss';
@import '@/assets/styles/tw-mixins.scss';

.tw-toast {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: $primary;
  color: $white;
  position: fixed;
  width: max-content;
  max-width: 80%;
  z-index: 2000;
  box-sizing: border-box;
  opacity: 1;
  @include tw-is-desktop {
    max-width: 460px;
  }

  .tw-toast-close-button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    border: none;
    background: none;
    color: inherit;
    font-size: 1.25rem;
    cursor: pointer;
    padding: 0;
    line-height: 1;
    border-radius: 50%;
    width: 30px;
    height: 30px;

    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
  &.toast-type--default {
    background-color: $success;
  }
  &.toast-type--success {
    background-color: $success;
  }
  &.toast-type--info {
    background-color: $info;
  }
  &.toast-type--warning {
    background-color: $primary;
  }
  &.toast-type--error {
    background-color: $red;
  }

  &.toast-position--top,
  &.toast-position--top-center {
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
  }
  &.toast-position--top-right {
    top: 1rem;
    right: 1rem;
  }
  &.toast-position--top-left {
    top: 1rem;
    left: 1rem;
  }
  &.toast-position--bottom {
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
  }
  &.toast-position--bottom-center {
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
  }
  &.toast-position--bottom-right {
    bottom: 1rem;
    right: 1rem;
  }
  &.toast-position--bottom-left {
    bottom: 1rem;
    left: 1rem;
  }
  &.toast-position--center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &.toast-position--center-left {
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
  }
  &.toast-position--center-right {
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
  }
  .tw-toast-header {
    padding-right: 28px;
    max-width: 100%;
    overflow-wrap: break-word;
    font-weight: bold;
    font-size: 1.4rem;
    margin: 0.2rem 0;
    @include tw-is-desktop {
      font-size: 1rem;
    }
  }
  .tw-toast-body {
    padding-right: 28px;
    max-width: 100%;
    overflow-wrap: break-word;
    font-size: 1rem;
    line-height: 1.4;
    margin: 0.2rem 0;
    @include tw-is-desktop {
      font-size: 0.9rem;
    }
  }
  .tw-toast-icon {
    font-size: 1.5rem;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
